import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { cn } from '@/shared-ui';
import type { GetSurveyQuery } from '@/survey-graphql';
import { SURVEY_RATING_COMPONENT_ID } from './survey-response.helpers';

interface Props {
  children?: React.ReactNode;
  loading?: boolean;
  selectedValue?: GetSurveyQuery['survey']['ratings'][0]['value'] | null;
  survey: GetSurveyQuery['survey'];
  onSelect: (value: GetSurveyQuery['survey']['ratings'][0]['value']) => void;
  accentColor?: string;
  className?: string;
}

export default function SurveyRatings({
  children,
  loading,
  survey: { primaryQuestion, ratings },
  selectedValue,
  onSelect,
  accentColor,
  className,
}: Props) {
  const labels = [ratings[0].label, ratings[ratings.length - 1].label];
  const isRatingsSectionNarrow = ratings.length <= 3;

  return (
    <div
      id={SURVEY_RATING_COMPONENT_ID}
      className={cn(
        'flex flex-col items-center gap-3 rounded-2xl border border-gray-100 bg-white px-4 py-5 shadow-sm',
        className
      )}
    >
      <p className="prose lg:prose-xl font-display text-balance text-center text-xl font-semibold leading-normal tracking-tight text-slate-800">
        {primaryQuestion}
      </p>

      <div className="flex flex-col items-center justify-start gap-1">
        <div
          className="flex max-w-[18rem] flex-wrap justify-center gap-1 data-[narrow=true]:gap-8"
          data-narrow={isRatingsSectionNarrow}
        >
          {ratings.map((rating) => (
            <button
              key={rating.value}
              data-selected={selectedValue === rating.value}
              data-small={ratings.length > 5}
              className="h-11 w-11 rounded-xl border font-medium shadow-sm enabled:hover:bg-zinc-50 disabled:cursor-not-allowed data-[small=true]:h-10 data-[small=true]:w-10 data-[selected=true]:border-red-500 data-[selected=true]:text-red-500"
              style={{
                borderColor:
                  selectedValue === rating.value ? accentColor : undefined,
                color: selectedValue === rating.value ? accentColor : undefined,
              }}
              onClick={() => onSelect(rating.value)}
              disabled={loading || selectedValue === rating.value}
            >
              {loading && selectedValue === rating.value ? (
                <FontAwesomeIcon
                  className="mx-auto h-4 w-4"
                  icon={faSpinner}
                  spin
                />
              ) : (
                rating.value
              )}
            </button>
          ))}
        </div>

        <div
          className="flex items-center justify-between self-stretch data-[narrow=true]:gap-[6.5rem]"
          data-narrow={isRatingsSectionNarrow}
        >
          {labels.map((label) => (
            <span key={label} className="text-xs text-gray-500">
              {label}
            </span>
          ))}
        </div>

        {children}
      </div>
    </div>
  );
}
