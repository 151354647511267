import { gql } from '../__generated__/gql';

export const LIST_PROJECTS = gql(/* GraphQL */ `
  query ListProjects {
    projects {
      id
      title
      published
      redirectUrl
      questions {
        id
        type
        text
        required
        aiInsights
        aiConvoDepth
        multipleSelect
        randomize
        options {
          id
          label
          value
        }
      }
      tags {
        id
        name
        color
      }
    }
  }
`);
