import ManagerBrandingDesktopPreview from './manager-branding-desktop-preview';

import { faClone, faDesktop, faMobile } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import ManagerBrandingMobilePreview from './manager-branding-mobile-preview';
import { ManagerBrandingPreviewOption } from './manager-branding-preview-option';
import ManagerBrandingWidgetPreview from './manager-branding-widget-preview';
export interface ManagerBrandingPreviewSectionProps {
  accentColor: string;
  logoUrl: string | null;
  avatarUrl: string | null;
  showPoweredBy: boolean;
}

const PREVIEW_OPTIONS = {
  desktop: ManagerBrandingDesktopPreview,
  widget: ManagerBrandingWidgetPreview,
  mobile: ManagerBrandingMobilePreview,
} as const;

export default function ManagerBrandingPreviewSection({
  accentColor,
  logoUrl,
  avatarUrl,
  showPoweredBy,
}: ManagerBrandingPreviewSectionProps) {
  const [previewOption, setPreviewOption] = useState<
    'desktop' | 'widget' | 'mobile'
  >('desktop');

  const PreviewComponent = PREVIEW_OPTIONS[previewOption];

  return (
    <div className="flex min-w-[50rem] flex-1 flex-col gap-4">
      <div className="flex justify-between self-stretch">
        <h1 className="text-lg font-bold text-gray-900">Preview</h1>
        <div className="flex items-center gap-2">
          <ManagerBrandingPreviewOption
            icon={<FontAwesomeIcon icon={faDesktop} />}
            onClick={() => setPreviewOption('desktop')}
            selected={previewOption === 'desktop'}
          />
          <ManagerBrandingPreviewOption
            icon={<FontAwesomeIcon icon={faClone} />}
            onClick={() => setPreviewOption('widget')}
            selected={previewOption === 'widget'}
          />
          <ManagerBrandingPreviewOption
            icon={<FontAwesomeIcon icon={faMobile} />}
            onClick={() => setPreviewOption('mobile')}
            selected={previewOption === 'mobile'}
          />
        </div>
      </div>
      <PreviewComponent
        accentColor={accentColor}
        logoUrl={logoUrl}
        avatarUrl={avatarUrl}
        showPoweredBy={showPoweredBy}
      />
    </div>
  );
}
